import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';

import Sorting from './components/Sorting';

import { Row } from './DashboardRows';
import GridItemForm from './components/GridItemForm';
import { IDashboardSort } from 'src/store/localStorage/forms';
import { IFolder, IShortFormData, IBaseData } from 'src/libs';

import ConfirmPopupButton from 'src/ui/Popups/ConfirmPopupBottom';
import { PopupBottom } from 'src/ui/Popups/PopupBottom';
import FormOptionsForPopup from './components/FormOptionsForPopup';
import ColorSelector from 'src/ui/ColorSelector';
import EditForm from './components/EditForm';
import ShareForm from 'src/ui/ShareForm/ShareForm';
import { useDispatch } from 'react-redux';
import { NotificationContext } from 'src/store/context/notifications';

import {
  addForm,
  colorForm,
  deleteForm,
  folderForm,
  getForms,
  labelFormAdd,
  labelFormRemove,
  pinForm,
  updateForm
} from 'src/store/reducers/forms/actions';
import GridItemFolder from './components/GridItemFolder';
import FolderOptionsForPopup from './components/FolderOptionsForPopup';
import { getFolders, updateFolder, deleteFolder } from 'src/store/reducers/folders/actions';
import EditGroupForPopup from './components/EditGroupForPopup';
import FolderSelector from './components/FolderSelector';
import LabelSelector from './components/LabelSelector';
import AddPartner from './components/AddPartner';
import {TMode} from "../workspace";
import { useTranslation } from 'src/i18n/useTranslation';

import {AutoSizer, CellMeasurer, CellMeasurerCache, createMasonryCellPositioner, Masonry, WindowScroller} from 'react-virtualized';
import api from 'src/api';

export default function DashboardGrid({
  folders,
  items,
  sort,
  onSort,
  onChangeFolder,
  onOpenAddGroupPopup,
  onOpenAddLabelPopup
}:{
  folders: IFolder[],
  items: IShortFormData[],
  sort: IDashboardSort,
  onSort(data: IDashboardSort): void,
  onChangeFolder(id: number): void,
  onOpenAddGroupPopup(value: boolean): void,
  onOpenAddLabelPopup(value: boolean): void;
}){
  const dispatch = useDispatch();
  const context = useContext(NotificationContext);
  const { t } = useTranslation();
  const [openPopup, setOpenPopup] = useState(false);
  const [openFolderOptionsPopup, setOpenFolderOptionsPopup] = useState(false);

  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [openColorPopup, setOpenColorPopup] = useState(false);
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [openFolderPopup, setOpenFolderPopup] = useState(false);
  const [openLabelPopup, setOpenLabelPopup] = useState(false);
  const [openSharePopup, setOpenSharePopup] = useState(false);
  const [openAddPartnerPopup, setOpenAddPartnerPopup] = useState(false);

  const [openDeleteFolderPopup, setOpenDeleteFolderPopup] = useState(false);
  const [openColorFolderPopup, setOpenColorFolderPopup] = useState(false);
  const [openEditFolderPopup, setOpenEditFolderPopup] = useState(false);

  const [form, setForm] = useState(items[0]);
  const [folder, setFolder] = useState(folders[0]);

  const actions = {
    togglePin(item: IShortFormData) {
      setOpenPopup(false);
      dispatch(pinForm(item.id, !item.isPinned));
      context?.add(t("chronos.mobile.dashboard.pinChanged"));
    },
    openEditPopup () {
      setOpenPopup(false);
      setOpenEditPopup(true);
    },
    openColorPopup() {
      setOpenPopup(false);
      setOpenColorPopup(true);
    },
    openFolderPopup() {
      setOpenPopup(false);
      setOpenFolderPopup(true);
    },
    openLabelPopup() {
      setOpenPopup(false);
      setOpenLabelPopup(true);
    },
    openDeletePopup() {
      setOpenPopup(false);
      setOpenDeletePopup(true);
    },
    openSharePopup () {
      setOpenPopup(false);
      setOpenSharePopup(true);
    },
    openAddPartnerPopup () {
      setOpenPopup(false);
      setOpenAddPartnerPopup(true);
    },
    duplicate(form: IShortFormData) {
      setOpenPopup(false);
      api.form(form.id).then((form: any) => {
        const newForm = {
          ...form,
          id: -1
        }
        dispatch(addForm(newForm, () => {
          context?.add(t("chronos.mobile.dashboard.theMapIsDuplicated"));
        }));
      
      })
    }
  }

  const folderActions = {
    togglePin(item: IFolder) {
      setOpenFolderOptionsPopup(false);
      const newItem: IFolder = {...item, is_pinned: !item.is_pinned};
      dispatch(updateFolder(newItem, () => {
        dispatch(getFolders(false));
      }));
      context?.add(t("chronos.mobile.dashboard.pinChanged"));
    },

    openDeletePopup (){
      setOpenFolderOptionsPopup(false);
      setOpenDeleteFolderPopup(true);
    },

    openEditPopup() {
      setOpenFolderOptionsPopup(false);
      setOpenEditFolderPopup(true);
    },

    openColorPopup() {
      setOpenPopup(false);
      setOpenColorFolderPopup(true);
    }
  }

  const confirmDeleteForm = (id: number) => {
    dispatch(deleteForm(id));
    context?.add(t("chronos.mobile.dashboard.mapRemoved"));
  }

  const changeColorForm = (color: string) => {
    dispatch(colorForm(form.id, color));
    context?.add(t("chronos.mobile.dashboard.colorChanged"));
  }

  const onUpdateFormNode = (node: TMode, nodeData?: IBaseData) => {
    setForm(form => {
      return {
        ...form,
        [node]: nodeData || null
      }
    })
  }

  const onOpenMenu = (form: IShortFormData) => {
    setOpenPopup(true);
    setForm(form);
  }

  const onOpenFolderMenu = (folder: IFolder) => {
    setOpenFolderOptionsPopup(true);
    setFolder(folder);
  }

  const confirmDeleteFolder = (id: number) => {
    dispatch(deleteFolder(id));
    context?.add(t("chronos.mobile.dashboard.groupDeleted"));
  }

  const changeColorFolder = (color: string) => {
    const newItem: IFolder = {...folder, color: color};
    dispatch(updateFolder(newItem, () => {
      dispatch(getFolders());
    }));
    context?.add(t("chronos.mobile.dashboard.colorChanged"));
  }

  const changeFormFolder = (formId: number, folderId: number) => {
    setOpenFolderPopup(false);
    dispatch(folderForm(+formId, folderId, () => {
      dispatch(getForms(false));
    }));
    context?.add(t("chronos.mobile.dashboard.mapMoved"));
  }

  const changeFormLabel = (isCurrentLabel: boolean, formId: number, labelId: number) => {
    if(!isCurrentLabel) {
      dispatch(labelFormAdd(+formId, labelId, () => {
        dispatch(getForms(false));
        context?.add(t("chronos.mobile.dashboard.theMarkIsSet"));
      }));
    } else {
      dispatch(labelFormRemove(+formId, labelId, () => {
        dispatch(getForms(false));
        context?.add(t("chronos.mobile.dashboard.markRemoved"));
      }));
    }

    setOpenLabelPopup(false);
    setOpenPopup(false);
  }

  const onUpdateForm = (newForm: any, mode?: TMode) => {
    setTimeout(() => {
      dispatch(updateForm(newForm!, () => {
      }));
      context?.add(t("chronos.mobile.dashboard.mapSettingsChanged"));
    }, 500)
  }

  const screenWidth = window.screen.width
  const paddings = 32
  const cellCount = folders.length + items.length
  const columnCount = Math.floor(screenWidth / 172)
  const cellHeight = 248
  const spacer = 15
  const cellWidth = (screenWidth - paddings - spacer) / 2

  let masonryRef: any;

  const cache = new CellMeasurerCache({
    defaultHeight: cellHeight,
    minWidth: cellWidth,
    fixedWidth: true,
  });

  const cellPositioner = createMasonryCellPositioner({
    cellMeasurerCache: cache,
    columnCount: columnCount,
    columnWidth: cellWidth,
    spacer: spacer,
  });

  const resetMasonry = () => {
    cache.clearAll();
    cellPositioner.reset({
      columnCount: columnCount,
      columnWidth: cellWidth,
      spacer: spacer,
    });
    masonryRef.clearCellPositions();
  }

  useEffect(() => {
    masonryRef && resetMasonry()
  })

  const cellRenderer = ({index, key, parent, style}: any) => {
    const commonArr = [...folders, ...items]

    const child = () => {
      if (index < folders.length) {
        //@ts-ignore
        const item: IFolder = commonArr[index]
        if (!item) return null
        return <Item key={key} style={style}>
          <GridItemFolder item={item} onOpenMenu={onOpenFolderMenu}
                          onChangeFolder={onChangeFolder}/>
        </Item>
      } else {
        //@ts-ignore
        const item: IFormData = commonArr[index]
        if (!item) return null
        return <Item key={key} style={style}>
          <GridItemForm item={item} onOpenMenu={onOpenMenu}/>
        </Item>
      }
    }

    return (
        <CellMeasurer cache={cache} index={index} key={key} parent={parent}>
          {child}
        </CellMeasurer>
    );
  }

  const renderMasonry = (height: any, width: any, scrollTop: any) => {
    return (
        <Masonry
            cellCount={cellCount}
            cellMeasurerCache={cache}
            cellPositioner={cellPositioner}
            cellRenderer={cellRenderer}
            width={width}
            height={height}
            autoHeight={true}
            ref={ref => (masonryRef = ref)}
            scrollTop={scrollTop}
        />
    );
  }

  return <div>
    <Row>
      <Sorting onSort={onSort} sort={sort} />
    </Row>

    <WindowScroller>
      {({height, scrollTop}) => (
          <AutoSizer disableHeight
                     scrollTop={scrollTop}>
            {({width}): any => {
              return renderMasonry(height, width, scrollTop)
            }
            }
          </AutoSizer>
      )}
    </WindowScroller>

    {form && <>
      <PopupBottom open={openPopup} onClose={() => setOpenPopup(false)}>
        <FormOptionsForPopup form={form} actions={actions} />
      </PopupBottom>

      <ConfirmPopupButton open={openDeletePopup} onClose={() => setOpenDeletePopup(false)} title={t("chronos.app.removeCard")} onConfirm={() => confirmDeleteForm(form.id)} confirmText={t("chronos.app.delete")}>
        {t("chronos.mobile.dashboard.deleteCard.description")}
      </ConfirmPopupButton>

      <PopupBottom open={openEditPopup} onClose={() => setOpenEditPopup(false)}>
        <EditForm 
          onClose={() => setOpenEditPopup(false)} 
          formId={form.id}
          open={openEditPopup} 
          onUpdateForm={onUpdateForm} 
          saveFormMode={'add'}
          onAddNode={(nodeData, node) => onUpdateFormNode(node, nodeData)}
          onRemoveNode={(node) => onUpdateFormNode(node)}
        />
      </PopupBottom>

      <PopupBottom open={openSharePopup} onClose={() => setOpenSharePopup(false)}>
        <ShareForm form={form} onUpdate={() => {}} isOpen={openSharePopup} />
      </PopupBottom>

      <PopupBottom open={openColorPopup} onClose={() => setOpenColorPopup(false)}>
        <ColorSelector current={form.color} onChange={(color) => changeColorForm(color)} />
      </PopupBottom>

      <PopupBottom open={openFolderPopup} onClose={() => setOpenFolderPopup(false)}>
        <FolderSelector form={form} onChange={changeFormFolder} onOpenAddGroupPopup={onOpenAddGroupPopup} onClose={() => setOpenFolderPopup(false)} />
      </PopupBottom>

      <PopupBottom open={openLabelPopup} onClose={() => setOpenLabelPopup(false)}>
        <LabelSelector form={form} onChange={changeFormLabel} onOpenAddGroupPopup={onOpenAddLabelPopup} onClose={() => setOpenLabelPopup(false)} />
      </PopupBottom>

      <PopupBottom open={openAddPartnerPopup} onClose={() => setOpenAddPartnerPopup(false)}>
        <AddPartner form={form} onClose={() => setOpenAddPartnerPopup(false)} />
      </PopupBottom>
    </> }

    {folder && <>
      <PopupBottom open={openFolderOptionsPopup} onClose={() => setOpenFolderOptionsPopup(false)}>
        <FolderOptionsForPopup folder={folder} actions={folderActions} />
      </PopupBottom>

      <ConfirmPopupButton open={openDeleteFolderPopup} onClose={() => setOpenDeleteFolderPopup(false)} title={t("chronos.app.dashboard.questiondeleteGroup")} onConfirm={() => confirmDeleteFolder(folder.id)} confirmText={t("chronos.app.delete")}>
      <Details>
        {t("chronos.mobile.dashboard.youDeleteGroup")} <strong>{folder.title}</strong> {t("chronos.mobile.dashboard.MoveToMain")} 
        </Details>
      </ConfirmPopupButton>

      <PopupBottom open={openEditFolderPopup} onClose={() => setOpenEditFolderPopup(false)} title={t("chronos.mobile.dashboard.deleteGroup.changeName")} back={true}>
        <EditGroupForPopup open={openEditFolderPopup} onClose={() => setOpenEditFolderPopup(false)} folder={folder}  />
      </PopupBottom>

      <PopupBottom open={openColorFolderPopup} onClose={() => setOpenColorFolderPopup(false)}>
        <ColorSelector current={folder.color} onChange={(color) => changeColorFolder(color)} />
      </PopupBottom>
    </> }

  </div>;
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  padding-bottom: 2rem;
`

const Item = styled.div`
  padding: 0.75rem;
  background: var(--bg-dashboard-cell);
  border-radius: 0.375rem;
`
const Details = styled.div`
  margin-top: 0.375rem;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--text-secondary);

  strong {
    color: var(--colors-red);
    font-weight: normal;
  }
`;
