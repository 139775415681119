import React, { ReactNode, useEffect, useRef, useState, memo, useCallback } from 'react';
import styled from 'styled-components';
import { throttle } from 'lodash';
import { SwipeEventData, useSwipeable } from 'react-swipeable';
import LineHorizontal from 'src/ui/LineHorizontal';
import {smoothHorizontalScrolling} from "../../utils";
import { TMode } from 'src/app/pages/workspace';


export interface ISwipeTab {
  key?: string| number;
  title: ReactNode | string;
  content: ReactNode | string;
}

export const swipeAndCall = (e: SwipeEventData, callback: any) => {
  if((e.event.target as HTMLElement).closest('.no-swipe')) return;
  if(e.velocity > 0.6 && Math.abs(e.deltaX) > 80) {
    callback();
  }
}

export default memo(function SwipeTabs({
  items,
  active,
  recalculateCondition,
  onChangeTab,
  open = false,
	openedContent
}: {
  items: ISwipeTab[];
  active?: TMode;
  open: boolean;
  recalculateCondition?: boolean;
  onChangeTab?: (mode: TMode) => void;
	openedContent?: boolean[];
}) {
  const tabsRef = useRef<HTMLDivElement>(null);
  const screensRef = useRef<HTMLDivElement>(null);
  const [currentIndex, setCurrentIndex] = useState(0);


  useEffect(() => {
    
    const width = window.outerWidth - 32; //getComputedStyle(tabsRef.current!).width//TODO:
  
    tabsRef.current!.style.width = `${width}px`;
    
    screensRef.current!.style.width = `${width}px`;
    screensRef.current!.querySelectorAll('.swipe-screen').forEach(item => {
      (item as HTMLDivElement).style.width = `${width}px`;
    })

    const handleScroll = throttle((e: any) => {
      // fixTabDimensions();
      // console.log(`< < <--- ---- handleScroll: ${typeof fixTabDimensions}  mode: ${active}`);
    }, 500);


    document.addEventListener('scroll', handleScroll);

    return () => document.removeEventListener('scroll', handleScroll);

  }, []); //currentIndex

  useEffect(() => {
    if (!open || !items) return;

    const activeTabIndex = items.findIndex(item => item.key === (active === 'syn_prognostics' ? 'prognostics' : active));
    if (activeTabIndex >= 0) {
      changeTab(activeTabIndex);
    };
  }, [open, items]);

  const fixTabDimensions = useCallback((tabIndex?: number) => {
    if (!screensRef.current || tabIndex === undefined) return;

    const currentScreen = screensRef.current && screensRef.current!.querySelectorAll('.swipe-screen')[tabIndex] as HTMLDivElement;
    screensRef.current.style.height = currentScreen.offsetHeight + 'px';

  }, [screensRef, active]);

  const changeTab = (index: number) => {
    
    if (index < 0 || index >= items.length) return;

    setCurrentIndex(index);

		setTimeout(() => {
      const currentTab = tabsRef.current!.querySelectorAll('.swipe-tab')[index] as HTMLDivElement

      // const currentScreen = screensRef.current!.querySelectorAll('.swipe-screen')[index] as HTMLDivElement
      //TODO: 16px to rem
      const left = currentTab.getBoundingClientRect().left
      tabsRef.current!.scrollTo({ left, behavior: 'smooth' })

      const width = getComputedStyle(tabsRef.current!).width;
      const isIPhone = navigator.platform === 'iPhone'

      if (isIPhone) {
        const distance = parseInt(width) * index - parseInt(width) * currentIndex
        smoothHorizontalScrolling(screensRef, 200, parseInt(width) * currentIndex, distance)
      } else {
        screensRef.current!.scrollTo({ 'left': index * parseInt(width), behavior: 'smooth' });
      }

      fixTabDimensions(index);
		}, 0)
  }

  

  // useEffect(() => {
  //   if (recalculateCondition === true) {
  //     changeTab(currentIndex);
  //   }

  //   console.log(`* - * ---- useEffect [props.recalculateCondition] -`, recalculateCondition);

  // }, [recalculateCondition]);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: (e) => swipeAndCall(e, () => changeTab(currentIndex + 1)),
    onSwipedRight: (e) => swipeAndCall(e, () => changeTab(currentIndex - 1)),
  });

  return <Container>

    <ScrollContainer ref={tabsRef}>
      <ItemsContainer>
        {items.map((item, index) =>
          <Tab key={index} className="swipe-tab" active={currentIndex === index} onClick={() => { changeTab(index); onChangeTab?.(item.key as TMode) }}>{item.title}</Tab>
        )}
      </ItemsContainer>
    </ScrollContainer>

    <LineWrapper><LineHorizontal /></LineWrapper>

    <ScreensScrollContainer ref={screensRef}>
      <ItemsContainer {...swipeHandlers}>
        {items.map((item, index) =>
          <Item key={index} className="swipe-screen">{item.content}</Item>
        )}
      </ItemsContainer>
    </ScreensScrollContainer>

  </Container>
});

export const TabWithIcons = styled.div`
  display: flex;
  align-items: center;
  > svg:nth-child(1) {
    margin-right: 0.75rem;
  }
  > svg:nth-child(2) {
    margin-left: 0.75rem;
  }
`

const Container = styled.div`
  position: relative;
`

const ScrollContainer = styled.div`
  position: relative;
  width: 100%;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`

const ScreensScrollContainer = styled(ScrollContainer)`
  position: relative;
  overflow: hidden;
`

const ItemsContainer = styled.div`
  position: relative;
  white-space: nowrap;
`

const Tab = styled.div<{ active?: boolean }>`
  height: 2.5rem;
  display: inline-flex;
  align-items: center;
  border-bottom: 0.125rem solid ${p => p.active ? 'var(--color-blue)' : 'transparent'};
  font-size: 0.875rem;

  color: ${p => p.active ? 'var(--text-primary)' : 'var(--text-third)'};

  &:not(:last-child) {
    margin-right: 1.625rem;
  }

`

const Item = styled.div`
  display: inline-flex;
  vertical-align: top;
`

const LineWrapper = styled.div`
  margin-top: -0.5rem;
`
